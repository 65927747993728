import { useTranslation } from "react-i18next";
import { AnswerButton } from "../components";
import { LongDistanceQuestionOptions } from "../../../../types";



export function LongDistanceFrequency({ setAnswer, answer }: { setAnswer: (v: string) => void, answer: string }) {

    const { t } = useTranslation("questionnaire");

    return (
        <div className="w-full sm:grid sm:grid-cols-5 sm:gap-4">
            {Object.entries(LongDistanceQuestionOptions).map(([k, v]) => <AnswerButton
                key={v}
                text={t(`longDistanceFrequency.options.${v}`)}
                isActive={answer === v}
                onClick={() => setAnswer(v)} />)}
        </div>
    )


}