import { FreeEvFinderVehicleCard } from "../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/FreeEvFinderVehicleCard";
import { AlternativeVehicle } from "../../../../../electrify_frontend_common/types";
import { useGtmTracking } from "../../../../../hooks/useGtmTracking";
import { useMoreInfo } from "../../../../../hooks/useMoreInfo";
import { useScreenSize } from "../../../../../hooks/useScreenSize";
import { QuestionnaireResults } from "../../../../../types";
import { EVFinderBanner } from "./EVFinderBanner";

import { useTranslation } from "react-i18next";

function EvRecommendationsMobile({ results }: { results: QuestionnaireResults }) {

    const { i18n } = useTranslation();
    const { openVehicleDetails } = useMoreInfo('quickCheck');

    const { offerClickedEventTracking } = useGtmTracking();

    return <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-4 w-full mt-4">
        {results.evSuggestions?.map((evs: Partial<AlternativeVehicle> & { filtersSatisfied: boolean }) =>
            <div className="col-span-1 h-full"><FreeEvFinderVehicleCard
                key={evs.evId}
                vehicle={evs}
                onClick={() => openVehicleDetails(evs.evId || "")}
                localeCode={i18n.language}
                offerTrackingCall={(callback : ()=> void)=>{
                    offerClickedEventTracking({
                        product: 'quickCheck',
                        callback
                    })
                }}
            /></div>
        )}
        <div className="col-span-1"><EVFinderBanner /></div>
    </div>
}


function EvRecommendationsDesktop({ results }: { results: QuestionnaireResults }) {

    const { i18n } = useTranslation();
    const { openVehicleDetails } = useMoreInfo('quickCheck');
    const { offerClickedEventTracking } = useGtmTracking();


    return <div className="grid grid-cols-12 gap-4 w-full mt-4">
        <div className="col-span-12 lg:col-span-8">
            <div className="grid grid-cols-3 gap-4 h-full">
                {results.evSuggestions?.map((evs: Partial<AlternativeVehicle> & { filtersSatisfied: boolean }) =>
                    <FreeEvFinderVehicleCard
                        key={evs.evId}
                        vehicle={evs}
                        onClick={() => openVehicleDetails(evs.evId || "")}
                        localeCode={i18n.language}
                        offerTrackingCall={(callback : ()=> void)=>{
                            offerClickedEventTracking({
                                product: 'quickCheck',
                                callback
                            })
                        }}
                    />
                )}
            </div>
        </div>
        <div className="col-span-12 lg:col-span-4"><EVFinderBanner /></div>
    </div>
}




export function EvRecommendations({ results }: { results: QuestionnaireResults }) {
    const { t } = useTranslation("results");
    const { widthIsLessThan1024 } = useScreenSize();

    const getViewBasedOnScreen = () => {
        if (widthIsLessThan1024) {
            return <EvRecommendationsMobile results={results} />;
        } else {
            return <EvRecommendationsDesktop results={results} />;
        }
    }

    return (
        <div className="flex flex-col w-full">
            <div className="border border-white flex justify-center p-2 text-xl rounded">
                {t("recommendedVehicles.title")}
            </div>
            {getViewBasedOnScreen()}

        </div>
    );
}
