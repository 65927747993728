import { VehicleList } from "./VehicleList";
import { useTranslation } from "react-i18next";
import searchImage from "../assets/search-image.webp";
import { EvFinderContext, EvFinderContextProvider } from "./EvFinderContext";
import { useContext, useEffect } from "react";
import { Filters } from "../electrify_frontend_common/components/EvFinder/Versions[A-B]/Filters";
import { pushToDataLayer, useGtmTracking } from "../hooks/useGtmTracking";



function EvFinderContent() {

    const { t } = useTranslation("evFinder");

    const { filters, updateFilters, resetFilters } = useContext(EvFinderContext)


    return <div className="flex flex-col w-full items-center">
        <div className="flex flex-col w-full min-h-screen text-white pt-12 p-6 sm:p-20 md:p-14 lg:p-20 max-w-screen-lg ">
            <div className="grid grid-cols-4 w-full ">
                <div className="hidden  col-span-1 md:flex justify-center">
                    <img className="object-contain" src={searchImage} />
                </div>
                <div className="col-span-4 md:col-span-3 ">
                    <div className="text-[30px] md:text-[3em] leading-none font-bold">
                        {t("title")}
                    </div>
                    <span className="text-xs md:text-sm flex w-full md:w-2/3 pt-4">
                        {t("descriptionOpen")}
                    </span>
                </div>
            </div>
            <div className="md:grid md:grid-cols-12 md:gap-4 lg:gap-8 w-full mt-6 ">
                <div className="md:col-span-4 lg:col-span-3">
                    <Filters backgroundColor="dark" filters={filters} updateFilters={updateFilters} currencySymbol="€" resetFilters={resetFilters} />
                </div>
                <div className="md:col-span-8 lg:col-span-9">
                    <VehicleList />
                </div>
            </div>
        </div>
    </div>;
}



export function EvFinder() {



    useEffect(() => {
        pushToDataLayer({
            event: 'productLoaded',
            metadata: {
                product: 'evFinder',
                page: '-'
            }
        })
    }, [])


    return (
        <EvFinderContextProvider>
            <EvFinderContent />
        </EvFinderContextProvider>
    );
}
