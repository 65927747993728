import { Button } from '../../../../components/Button';
import { ButtonSize, ButtonVariant } from '../../../../components/Button/types';
import { Question } from '../../../../types';
import { useTranslation } from 'react-i18next';

export function QuestionWrapper({
    label,
    questionText,
    additionalText,
    children,
    back,
    next,
    optional,
    answer,
}: // currentQuestion
    {
        label: string;
        questionText: string;
        additionalText?: string;
        children: React.ReactNode;
        back?: () => void;
        next: (action: 'next' | 'skip') => void;
        optional?: boolean;
        answer: any;
        // currentQuestion: Question
    }) {
    return (
        <div className="flex flex-col w-full h-full justify-center">
            <div>
                <QuestionAndLabel label={label} question={questionText} additionalText={additionalText} />
                {children}
                <NavigationButtons next={next} back={back} optional={optional} answer={answer} />
            </div>
        </div>
    );
}

export function QuestionAndLabel({ label, question, additionalText }: { label: string; question: string; additionalText?: string; }) {
    const { t } = useTranslation('questionnaire');

    return (
        <div className="flex flex-col w-full items-center justify-center text-white mb-12">
            <h4 id="topic-label" className="mb-6 md:text-xl">
                {t('topic')}: <span className="font-bold">{label}</span>
            </h4>
            <h1 className="text-[24px] md:text-[40px] text-center">{question}</h1>
            {additionalText ? <h4 className="text-xl italic mt-2"> {additionalText}</h4> : null}
        </div>
    );
}

export function NavigationButtons({ next, back, optional, answer }: {
    next: (action: 'next' | 'skip') => void;
    back?: () => void;
    optional?: boolean;
    answer: any;
}) {
    const { t } = useTranslation('questionnaire');

    return (
        <div className={`flex w-full mt-12 md:mt-20 ${back? 'justify-between' : 'justify-end'}`}>
            {back ?
                <Button variant={ButtonVariant.TERTIARY} size={ButtonSize.LARGE} onClick={back}>
                    {t('navigationButtons.back')}
                </Button> :
                null}
            <Button
                disabled={!optional && !answer}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                onClick={()=> next(optional && !answer ? 'skip' : 'next')}
            >
                {optional && !answer ? t('navigationButtons.skip') : t('navigationButtons.next')}
            </Button>
        </div>
    );
}

export function AnswerButton({
    text,
    isActive,
    onClick,
    className,
    isDisabled,
}: {
    text: string;
    isActive: boolean;
    onClick: () => void;
    className?: string;
    isDisabled?: boolean;
}) {
    const getTextColor = () => {
        if (isActive || isDisabled) return 'text-black';
        return 'text-white';
    };

    return (
        <div
            onClick={() => (isDisabled ? null : onClick())}
            style={{
                backgroundColor: isDisabled ? '#78787A' : '',
            }}
            className={`mb-4 sm:mb-0 border border-white rounded-md px-0 sm:px-3 md:px-0 h-16 sm:h-20 lg:h-16 col-span-1 flex items-center justify-center bg-transparent cursor-pointer text-center text-sm md:text-lg
            ${!isActive && !isDisabled ? 'md:hover:bg-Blueberry-light-shade' : ''}
            ${isActive ? 'bg-white ' : ''}
            ${isDisabled ? ` cursor-auto ` : ''}
            ${className}
            ${getTextColor()}
            `}
        >
            <>{text}</>
        </div>
    );
}

export function BottomBar({ currentQuestion }: { currentQuestion: Question }) {
    const totalPages = Object.keys(Question).filter(key => isNaN(Number(key))).length - 1;
    const currentQuestionIndex = Object.values(Question)
        .filter(key => isNaN(Number(key)))
        .indexOf(currentQuestion.toString() as Question);

    return (
        <div className="flex h-3 w-full bg-white">
            <div
                className="h-3 bg-Blueberry-dark-default"
                style={{
                    width: `${(currentQuestionIndex / totalPages) * 100}%`,
                    transition: '0.3s',
                }}
            ></div>
        </div>
    );
}
