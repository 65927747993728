import { AlternativeVehicle } from "../electrify_frontend_common/types";

export enum Question {
    // E_MOBILITY = "eMobility",
    HOME_CHARGING = "homeCharging",
    CHARGING_POSSIBILITIES = "chargingPossibilities",
    WORKPLACE_CHARGING = "workplaceCharging",
    WORKPLACE_ATTENDANCY = "workplaceAttendancy",
    DRIVING_BEHAVIOR = "drivingBehavior",
    YEARLY_MILEAGE = "yearlyMileage",
    LONG_TRIPS_FREQUENCY = "longTripsFrequency",
    PRICE = "price",
    ESSENTIAL_FEATURES = "essentialFeatures",
    NICE_TO_HAVE_FEATURES = "niceToHaveFeatures",
}

export enum ChargingQuestionOptions {
    NO = "no",
    NOT_SURE = "notSure",
    NOT_YET_BUT_IN_THE_FUTURE = "inTheFuture",
    YES = "yes",
}

export enum WorkplaceChargingFrequencyQuestionOptions {
    NEVER = "never",
    ONCE_A_MONTH = "oneTimePerMonth",
    ONCE_PER_WEEK = "oneTimePerWeek",
    TWICE_PER_WEEK = "twoTimesPerWeek",
    THRICE_PER_WEEK = "threeTimesPerWeek",
    FOUR_PLUS_TIMES_PER_WEEK = "fourTimesPerWeekOrMore",
}

export enum DrivingBehaviorQuestionOptions {
    ONLY_BUSINESS = "onlyBusiness",
    MOSTLY_BUSINESS = "mostlyBusiness",
    MOSTLY_PRIVATE = "mostlyPrivate",
    ONLY_PRIVATE = "onlyPrivate",
}

export enum YearlyMileageQuestionOptions {
    LESS_THANK_10K = "to10k",
    BETWEEN_10K_AND_15K = "from10kTo15k",
    BETWEEN_15K_AND_20K = "from15kTo20k",
    BETWEEN_20K_AND_30K = "from20kTo30k",
    BETWEEN_30K_AND_50K = "from30kTo50k",
    MORE_THAN_50K = "from50k",
}

export enum LongDistanceQuestionOptions {
    NEVER = "never",
    EVERY_TWO_MONTHS = "onceEveryTwoMonths",
    ONCE_A_MONTH = "onceEveryMonth",
    TWICE_A_MONTH = "twiceAMonth",
    MORE_OFTEN = "moreOften",
}

export enum PriceQuestionOptions {
    LESS_THAN_THIRTY_K = "to30k",
    FOURTY_K = "40k",
    SIXTY_K = "60k",
    EIGHTY_K = "80k",
    NO_LIMIT = "noLimit",
}

export enum EssentialFeaturesQuestionOptions {
    "4x4" = "4x4",
    SIX_PLUS_SEATS = "sixPlusSeats",
    ISOFIX_AVAILABLE = "isofixAvailable",
    TOWING = "towing",
    FAST_CHARGING = "fastCharging",
    LARGE_LOAD_VOLUME = "largeLoadVolume",
    REAL_RANGE = "highRange",
    HIGH_SPEED = "highTopSpeed",
}

export type AnswerPayload = {
    [k in Question]: null | string | number | LocationsCountAnswer | [];
};

export type LocationsCountAnswer = {
    homeAddressInputedAndMappedToCoordinates: boolean;
    countOfLocationsInSmallCircle?: number;
    countOfEvsesInSmallCircle?: number;
    countOfLocationsInBigCircle?: number;
    countOfEvsesInBigCircle?: number;
};

export type SuitabilityCategory =
    | "noRestrictions"
    | "veryComfortable"
    | "fairlyComfortable"
    | "adaptationsNeeded"
    | "notRecommended";

export interface PublicLocation {
    id: number;
    lat: number;
    lon: number;
    name: string;
    powerType: string[];
    minimumPower: number;
    maximumPower: number;
    countOfEvses: number;
}

export interface Location {
    __typename?: string;
    id?: string;
    name?: string;
    street?: string;
    streetNr?: string;
    zip?: string;
    city?: string;
    country?: string;
    power?: number | null;
    valid?: boolean;
    energyPrice?: number | null;
    energyCo2?: number | null;
    lat: number;
    lon: number;
}

// interface OfferToDisplay {
//     price: number;
//     url: string;
// }

// export interface AlternativeVehicle {
//     _id: string;
//     evId: string;
//     make: string;
//     model: string;
//     availableNow: boolean;
//     category: string;
//     segment: string;
//     bodyStyle: string;
//     drivetrainPropulsion: string;
//     drivetrainType: string;
//     towHitchPossible: boolean;
//     seatsCount: number;
//     electrificationFactor: number;
//     yearlyFuelAndEnergyCosts: number;
//     yearlyCo2Emission: number;
//     listPrice: number;
//     isSelected: boolean;
//     isFavorited: boolean;
//     notInPreference: boolean;
//     hasHighestScore: boolean;
//     hasHighestElectrificationFactor: boolean;
//     hasLowestFuelAndEnergyCosts: boolean;
//     notRecommended: boolean;
//     energyConsumption: number;
//     range: number;
//     offerToDisplay?: OfferToDisplay;
// }

export type QuestionnaireResults = {
    suitabilityCategory: SuitabilityCategory;
    evSuggestions: Partial<AlternativeVehicle> &
    { filtersSatisfied: boolean }[];
    recommendedActions: RecommendedAction[];
};

export type RecommendedAction =
    | "homeChargingNeeded"
    | "veryLongRangeNeeded"
    | "longRangeNeeded"
    | "publicChargingNearHomeNeeded"
    | "workplaceChargingNeeded"
    | "publicChargingNearWorkplaceNeeded";

// export type IFilters = {
//     brands: string[] | null;
//     availabilityStatus: "AVAILABLE" | "NOT_AVAILABLE" | null;
//     listPrice: { min: number | null; max: number | null };
//     topSpeed: 140 | 170 | 200 | 250 | null;
//     realRange: 250 | 300 | 400 | 500 | null;
//     realConsumption: 16 | 18 | 20 | 22 | null;
//     loadWeight: 400 | 500 | 600 | 700 | null;
//     loadVolume: 350 | 400 | 500 | 700 | null;
//     seats: 2 | 4 | 5 | 7 | null;
//     "4x4": boolean | null;
//     fastCharging: boolean | null;
//     towing: boolean | null;
//     plugAndCharge: boolean | null;
//     vehicleToGrid: boolean | null;
//     isofixAvailable: boolean | null;
//     roofRails: boolean | null;
// };

export type FiltersOptions = {
    label: string;
    value: number | string | boolean;
};

export type AlternativeEletricVehicle = {
    vehicleId: string;
    evId: string;
    isFavorited?: boolean;
    category: string;
    bodyStyle: string;
    yearlyEnergyConsumption: number;
    yearlyFuelAndEnergyCosts: number;
    model: string;
    make: string;

    listPrice: number;
    electrificationFactor: number;
    evModelData: {
        coreData: {
            isAvailable: boolean;
            acChargePower: number;
            dcChargeMaxPower: number;
            energyConsumption?: number;
        };
        technicalData: {
            acceleration: number;
            topSpeed: number;
            totalPower: number;
            totalPowerHp: number;
            totalTorque: number;
            propulsion: string;
            vehicleConsumption: number;
            vehicleConsumption100Km: number;
        };
        range: {
            expectedRealRangeAverage: number;
            expectedRealRangeHighwayCold: number;
            expectedRealRangeCityCold: number;
            expectedRealRangeCombinedCold: number;
            expectedRealRangeHighwayMild: number;
            expectedRealRangeCityMild: number;
            expectedRealRangeCombinedMild: number;
            rangeWLTPLow: number;
            rangeWLTPHigh: number;
        };
        battery: {
            nominalCapacity: number;
            usableCapacity: number;
            batteryType: string;
        };
        charging: {
            acChargePort: string;
            acPortLocation: string;
            acChargePower: number;
            acChargeTime: number;
            dcChargePort: string;
            dcPortLocation: string;
            dcChargePower: number;
            dcChargeTime: number;
        };
        dimensionsAndWeight: {
            length: number;
            width: number;
            widthWithMirrors: number;
            height: number;
            wheelbase: number;
            weightUnladen: number;
            grossVehicleWeight: number;
            maxPayload: number;
            cargoVolume: number;
            cargoVolumeMax: number;
            cargoVolumeFrunk: number;
        };
        miscellaneous: {
            towHitchPossible: boolean;
            seats: number;
            isofixSeats: boolean;
            roofRails: boolean;
            turningCircle: number;
        };
    };
};

export interface AutocompleteMatch {
    length: number;
    offset: number;
}
export interface GoogleAutocompletePredictionsResponse {
    description: string,
    matched_substrings: AutocompleteMatch[],
    place_id: string;
    reference: string;
    structured_formatting: {
        main_text: string,
        main_text_matched_substrings: AutocompleteMatch[],
        secondary_text: string
    },
    terms: { offset: number, value: string }[];
    types: string[];
    isHouseAddress: boolean;
};


export type TrackingEvent = {
    product: 'evFinder' | 'quickCheck',
    page: Question | string,
    action: 'next' | 'evFinderAd' | 'electrifyDriverAd' | 'electrificationPlannerAd' | 'filtersUpdated' | 'sortingUpdated' | 'moreInfoRequested' | 'productLoad' | 'offerClicked'
}