import { useQuestions } from "./useQuestions";
import { BottomBar, QuestionWrapper } from "./components";
import { useScroll } from "../../../hooks/useScroll";
import { useEffect } from "react";



export function Questionnaire({ showResults }: { showResults: () => void }) {

    const {
        questions,
        answers,
        currentQuestion,
    } = useQuestions(showResults);

    const {scrollToElementById} = useScroll();
    
    useEffect(()=> {
        scrollToElementById('topic-label');
    },[currentQuestion])
    


    if (!questions[currentQuestion]) return null;

    return (<div className="flex flex-col w-full items-center flex-1 justify-between">
        <div className="px-6 py-10 sm:px-12 lg:px-48 flex flex-col w-full flex-1 max-w-screen-lg justify-center">
            {questions[currentQuestion] !== null ?
                <QuestionWrapper
                    next={questions[currentQuestion].next}
                    back={questions[currentQuestion].back}
                    label={questions[currentQuestion].label}
                    questionText={questions[currentQuestion].questionText}
                    additionalText={questions[currentQuestion].additionalText}
                    optional={questions[currentQuestion].optional}
                    answer={answers[currentQuestion]}
                >
                    {questions[currentQuestion]?.component}
                </QuestionWrapper>
                : null
            }

        </div>
        <BottomBar currentQuestion={currentQuestion} />

    </div>


    )
}