import { useTranslation } from "react-i18next";
import { AnswerButton } from "../components";
import { EssentialFeaturesQuestionOptions } from "../../../../types";


export function Features({ setAnswer, answer, preSelectedAnswers }: { setAnswer: (v: string) => void, answer: string, preSelectedAnswers?: EssentialFeaturesQuestionOptions[] }) {

    const { t } = useTranslation("questionnaire");

    return (
        <div className="w-full grid grid-cols-2 gap-2 sm:grid sm:grid-cols-4 sm:gap-4">
            {Object.entries(EssentialFeaturesQuestionOptions).map(([k, v]) => <AnswerButton
                key={v}
                text={t(`essentialFeatures.options.${v}`)}
                isActive={answer?.includes(v)}
                onClick={() => setAnswer(v)}
                className="cursor-pointer"
                isDisabled={preSelectedAnswers?.includes(v)}
            />)}
        </div>
    )


}