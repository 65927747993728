import { ReactElement, useEffect, useState } from "react";
import {
    AnswerPayload,
    ChargingQuestionOptions,
    EssentialFeaturesQuestionOptions,
    LocationsCountAnswer,
    Question,
} from "../../../types";
import { useTranslation } from "react-i18next";
import { EMobility } from "./questions/EMobility";
import { Charging } from "./questions/Charging";
import { ChargingPossibilities } from "./questions/ChargingPossibilities";
import { DrivingBehavior } from "./questions/DrivingBehavior";
import { WorkplaceAttendancy } from "./questions/WorkplaceAttendancy";
import { DrivingDistance } from "./questions/DrivingDistance";
import { LongDistanceFrequency } from "./questions/LongDistanceFrequency";
import { Features } from "./questions/Features";
import { Price } from "./questions/Price";
import { useGtmTracking } from "../../../hooks/useGtmTracking";


export function useQuestions(showResults: () => void) {
    const { t } = useTranslation("questionnaire");

    const { questionnaireEventTrackingWrapper } = useGtmTracking();


    const [currentQuestion, setCurrentQuestion] = useState<Question>(
        (localStorage.getItem("currentQuestion") as Question) ||
        Question.HOME_CHARGING.toString() // For Campaign: Switched from E_MOBILITY
    );
    const [answers, setAnswers] = useState<AnswerPayload>(
        JSON.parse(localStorage.getItem("answers") || "{}")
    );

    const rules = {
        // [Question.E_MOBILITY]: {
        //     next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
        //         page: Question.E_MOBILITY,
        //         callback: () => setCurrentQuestion(Question.HOME_CHARGING)
        //     }),
        //     back: null,
        //     setAnswer: (value: number) =>
        //         setAnswers({ ...answers, [Question.E_MOBILITY]: value }),
        // },
        [Question.HOME_CHARGING]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.HOME_CHARGING,
                callback: () => manageHomeChargingJumpForward()
            }),
            back: null, // For Campaign: Switched from () => setCurrentQuestion(Question.E_MOBILITY)
            setAnswer: (value: string) => {
                if (value === ChargingQuestionOptions.YES) {
                    setAnswers({ ...answers, [Question.HOME_CHARGING]: value, [Question.CHARGING_POSSIBILITIES]: null });
                    return;
                }
                setAnswers({ ...answers, [Question.HOME_CHARGING]: value });
            },
            options: ChargingQuestionOptions,
        },
        [Question.CHARGING_POSSIBILITIES]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.CHARGING_POSSIBILITIES,
                callback: () => setCurrentQuestion(Question.WORKPLACE_CHARGING)
            }),
            back: () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.CHARGING_POSSIBILITIES,
                callback: () => setCurrentQuestion(Question.HOME_CHARGING)
            }),
            setAnswer: (value: LocationsCountAnswer | null) =>
                setAnswers({ ...answers, [Question.CHARGING_POSSIBILITIES]: value }),
        },
        [Question.WORKPLACE_CHARGING]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.WORKPLACE_CHARGING,
                callback: () => setCurrentQuestion(Question.WORKPLACE_ATTENDANCY)
            }),
            back: () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.WORKPLACE_CHARGING,
                callback: () => manageWorkplaceChargingJumpBackward(),
            }),
            setAnswer: (value: string) =>
                setAnswers({ ...answers, [Question.WORKPLACE_CHARGING]: value }),
        },
        [Question.WORKPLACE_ATTENDANCY]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.WORKPLACE_ATTENDANCY,
                callback: () => setCurrentQuestion(Question.DRIVING_BEHAVIOR)
            }),
            back: () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.WORKPLACE_ATTENDANCY,
                callback: () => setCurrentQuestion(Question.WORKPLACE_CHARGING)
            }),
            setAnswer: (value: string) =>
                setAnswers({ ...answers, [Question.WORKPLACE_ATTENDANCY]: value }),
        },
        [Question.DRIVING_BEHAVIOR]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.DRIVING_BEHAVIOR,
                callback: () => setCurrentQuestion(Question.YEARLY_MILEAGE)
            }),
            back: () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.DRIVING_BEHAVIOR,
                callback: () => setCurrentQuestion(Question.WORKPLACE_ATTENDANCY)
            }),
            setAnswer: (value: string) =>
                setAnswers({ ...answers, [Question.DRIVING_BEHAVIOR]: value }),
        },
        [Question.YEARLY_MILEAGE]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.YEARLY_MILEAGE,
                callback: () => setCurrentQuestion(Question.LONG_TRIPS_FREQUENCY)
            }),
            back: () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.YEARLY_MILEAGE,
                callback: () => setCurrentQuestion(Question.DRIVING_BEHAVIOR),
            }),
            setAnswer: (value: string) =>
                setAnswers({ ...answers, [Question.YEARLY_MILEAGE]: value }),
        },
        [Question.LONG_TRIPS_FREQUENCY]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.LONG_TRIPS_FREQUENCY,
                callback: () => setCurrentQuestion(Question.PRICE)
            }),
            back: () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.LONG_TRIPS_FREQUENCY,
                callback:  () => setCurrentQuestion(Question.YEARLY_MILEAGE),
            }),
            setAnswer: (value: string) =>
                setAnswers({ ...answers, [Question.LONG_TRIPS_FREQUENCY]: value }),
        },
        [Question.PRICE]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.PRICE,
                callback: () => setCurrentQuestion(Question.ESSENTIAL_FEATURES)
            }),
            back:  () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.PRICE,
                callback: () => setCurrentQuestion(Question.LONG_TRIPS_FREQUENCY)
            }),
            setAnswer: (value: string) =>
                setAnswers({ ...answers, [Question.PRICE]: value }),
        },
        [Question.ESSENTIAL_FEATURES]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.ESSENTIAL_FEATURES,
                callback: () => {
                    if (!answers[Question.ESSENTIAL_FEATURES])
                        setAnswers({ ...answers, [Question.ESSENTIAL_FEATURES]: [] });
                    setCurrentQuestion(Question.NICE_TO_HAVE_FEATURES);
                }
            }),
            back:  () => questionnaireEventTrackingWrapper({
                action: 'back',
                page: Question.ESSENTIAL_FEATURES,
                callback: () => setCurrentQuestion(Question.PRICE)
            }),
            setAnswer: (value: string) =>
                setAnswers((answer: any) => {
                    const currentAnswer =
                        (answer[Question.ESSENTIAL_FEATURES] as string[]) || [];
                    if (currentAnswer.includes(value)) {
                        return {
                            ...answer,
                            [Question.ESSENTIAL_FEATURES]: currentAnswer.filter((v: string) => v !== value),
                        };
                    }
                    if (answer[Question.NICE_TO_HAVE_FEATURES]?.includes(value)) {
                        return {
                            ...answer,
                            [Question.ESSENTIAL_FEATURES]: [...currentAnswer, value],
                            [Question.NICE_TO_HAVE_FEATURES]: answer[Question.NICE_TO_HAVE_FEATURES].filter((v: string) => v !== value),
                        };
                    }
                    return {
                        ...answer,
                        [Question.ESSENTIAL_FEATURES]: [...currentAnswer, value],
                    };
                }),
        },
        [Question.NICE_TO_HAVE_FEATURES]: {
            next: (action: 'next' | 'skip') => questionnaireEventTrackingWrapper({
                action,
                page: Question.NICE_TO_HAVE_FEATURES,
                callback: () => {
                    if (!answers[Question.NICE_TO_HAVE_FEATURES])
                        setAnswers({ ...answers, [Question.NICE_TO_HAVE_FEATURES]: [] });
                    localStorage.setItem("questionnaireCompleted", "true");
                    showResults();
                }
            }),
            back: () => questionnaireEventTrackingWrapper({
                action : 'back',
                page: Question.NICE_TO_HAVE_FEATURES,
                callback: () => setCurrentQuestion(Question.ESSENTIAL_FEATURES),
            }),
            setAnswer: (value: string) =>
                setAnswers((answer: any) => {
                    const currentAnswer = (answer[Question.NICE_TO_HAVE_FEATURES] as string[]) || [];
                    if (currentAnswer.includes(value)) {
                        return {
                            ...answer,
                            [Question.NICE_TO_HAVE_FEATURES]: currentAnswer.filter((v: string) => v !== value),
                        };
                    }
                    return {
                        ...answer,
                        [Question.NICE_TO_HAVE_FEATURES]: [...currentAnswer, value],
                    };
                }),
        },
    };

    const questions: {
        [k in Question]: {
            component: ReactElement;
            next: (action: 'next' | 'skip') => void;
            back?: () => void;
            label: string;
            questionText: string;
            optional?: boolean;
            additionalText?: string;
        };
    } = {

        [Question.HOME_CHARGING]: {
            component: (
                <Charging
                    setAnswer={rules[Question.HOME_CHARGING].setAnswer}
                    answer={answers[Question.HOME_CHARGING] as string}
                />
            ),
            next: rules[Question.HOME_CHARGING].next,
            //back: rules[Question.HOME_CHARGING].back,
            label: t("homeCharging.label"),
            questionText: t("homeCharging.question"),
        },
        [Question.CHARGING_POSSIBILITIES]: {
            component: (
                <ChargingPossibilities
                    setAnswer={rules[Question.CHARGING_POSSIBILITIES].setAnswer}
                    answer={
                        answers[
                        Question.CHARGING_POSSIBILITIES
                        ] as LocationsCountAnswer
                    }
                />
            ),
            next: rules[Question.CHARGING_POSSIBILITIES].next,
            back: rules[Question.CHARGING_POSSIBILITIES].back,
            label: t("chargingPossibilities.label"),
            questionText: t("chargingPossibilities.question"),
            optional: true,
        },
        [Question.WORKPLACE_CHARGING]: {
            component: (
                <Charging
                    setAnswer={rules[Question.WORKPLACE_CHARGING].setAnswer}
                    answer={answers[Question.WORKPLACE_CHARGING] as string}
                />
            ),
            next: rules[Question.WORKPLACE_CHARGING].next,
            back: rules[Question.WORKPLACE_CHARGING].back,
            label: t("workplaceCharging.label"),
            questionText: t("workplaceCharging.question"),
        },
        [Question.WORKPLACE_ATTENDANCY]: {
            component: (
                <WorkplaceAttendancy
                    setAnswer={rules[Question.WORKPLACE_ATTENDANCY].setAnswer}
                    answer={answers[Question.WORKPLACE_ATTENDANCY] as string}
                />
            ),
            next: rules[Question.WORKPLACE_ATTENDANCY].next,
            back: rules[Question.WORKPLACE_ATTENDANCY].back,
            label: t("workplaceAttendancy.label"),
            questionText: t("workplaceAttendancy.question"),
        },
        [Question.DRIVING_BEHAVIOR]: {
            component: (
                <DrivingBehavior
                    setAnswer={rules[Question.DRIVING_BEHAVIOR].setAnswer}
                    answer={answers[Question.DRIVING_BEHAVIOR] as string}
                />
            ),
            next: rules[Question.DRIVING_BEHAVIOR].next,
            back: rules[Question.DRIVING_BEHAVIOR].back,
            label: t("drivingBehavior.label"),
            questionText: t("drivingBehavior.question"),
        },
        [Question.YEARLY_MILEAGE]: {
            component: (
                <DrivingDistance
                    setAnswer={rules[Question.YEARLY_MILEAGE].setAnswer}
                    answer={answers[Question.YEARLY_MILEAGE] as string}
                />
            ),
            next: rules[Question.YEARLY_MILEAGE].next,
            back: rules[Question.YEARLY_MILEAGE].back,
            label: t("drivingDistance.label"),
            questionText: t("drivingDistance.question"),
        },
        [Question.LONG_TRIPS_FREQUENCY]: {
            component: (
                <LongDistanceFrequency
                    setAnswer={rules[Question.LONG_TRIPS_FREQUENCY].setAnswer}
                    answer={answers[Question.LONG_TRIPS_FREQUENCY] as string}
                />
            ),
            next: rules[Question.LONG_TRIPS_FREQUENCY].next,
            back: rules[Question.LONG_TRIPS_FREQUENCY].back,
            label: t("longDistanceFrequency.label"),
            questionText: t("longDistanceFrequency.question"),
        },
        [Question.PRICE]: {
            component: (
                <Price
                    setAnswer={rules[Question.PRICE].setAnswer}
                    answer={answers[Question.PRICE] as string}
                />
            ),
            next: rules[Question.PRICE].next,
            back: rules[Question.PRICE].back,
            label: t("price.label"),
            questionText: t("price.question"),
        },
        [Question.ESSENTIAL_FEATURES]: {
            component: (
                <Features
                    setAnswer={rules[Question.ESSENTIAL_FEATURES].setAnswer}
                    answer={answers[Question.ESSENTIAL_FEATURES] as string}
                />
            ),
            next: rules[Question.ESSENTIAL_FEATURES].next,
            back: rules[Question.ESSENTIAL_FEATURES].back,
            label: t("essentialFeatures.label"),
            questionText: t("essentialFeatures.question"),
            additionalText: t("essentialFeatures.additionalText"),
            optional: true,
        },
        [Question.NICE_TO_HAVE_FEATURES]: {
            component: (
                <Features
                    setAnswer={rules[Question.NICE_TO_HAVE_FEATURES].setAnswer}
                    answer={answers[Question.NICE_TO_HAVE_FEATURES] as string}
                    preSelectedAnswers={
                        answers[
                        Question.ESSENTIAL_FEATURES
                        ] as EssentialFeaturesQuestionOptions[]
                    }
                />
            ),
            next: rules[Question.NICE_TO_HAVE_FEATURES].next,
            back: rules[Question.NICE_TO_HAVE_FEATURES].back,
            label: t("essentialFeatures.label"),
            questionText: t("essentialFeatures.questionForPlus"),
            additionalText: t("essentialFeatures.additionalText"),
            optional: true,
        },
    };

    const manageHomeChargingJumpForward = () => {
        answers[Question.HOME_CHARGING] !== ChargingQuestionOptions.YES
            ? setCurrentQuestion(Question.CHARGING_POSSIBILITIES)
            : setCurrentQuestion(Question.WORKPLACE_CHARGING);
    };

    const manageWorkplaceChargingJumpBackward = () => {
        answers[Question.HOME_CHARGING] !== ChargingQuestionOptions.YES
            ? setCurrentQuestion(Question.CHARGING_POSSIBILITIES)
            : setCurrentQuestion(Question.HOME_CHARGING);
    };

    useEffect(() => {
        localStorage.setItem("currentQuestion", currentQuestion.toString());
    }, [currentQuestion]);

    useEffect(() => {
        localStorage.setItem("answers", JSON.stringify(answers));
    }, [answers]);

    return {
        questions,
        currentQuestion,
        rules,
        answers,
    };
}
