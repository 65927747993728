
export function ArrowLeft({color}: {color?: string}) {


    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.44444 12.4444L1 6.99999M1 6.99999L6.44444 1.55554M1 6.99999L15 6.99999" stroke={color? color : "white"} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )


}