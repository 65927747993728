import { useTranslation } from "react-i18next";
import { AnswerButton } from "../components";
import { YearlyMileageQuestionOptions } from "../../../../types";



export function DrivingDistance({ setAnswer, answer }: { setAnswer: (v: string) => void, answer: string }) {
    
    const { t } = useTranslation("questionnaire");
    
    return (
        <div className="w-full sm:grid sm:grid-cols-6 sm:gap-4">
            {Object.entries(YearlyMileageQuestionOptions).map(([k, v]) => <AnswerButton key={v} text={t(`drivingDistance.options.${v}`)} isActive={answer === v} onClick={() => setAnswer(v)} />)}
        </div>
    )


}