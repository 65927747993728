import { Circle, MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePublicChargingData } from "./usePublicChargingData";
import { CentralMarker, PublicChargersMarkers } from "./Markers";
import { LocationsCountAnswer, PublicLocation } from "../../../../../types";
import { NearbyChargingPoints } from "./NearbyChargingPoints";
import { DropDownInput } from "./DropdownInput";

function FlyToMap({
    setCurrentBounds,
    boundRef,
    coordinates,
    chargingPoints,
    setNearbyChargingPoints,
}: {
    setCurrentBounds: Dispatch<
        SetStateAction<{
            northEast: { lat: number; lon: number };
            southWest: { lat: number; lon: number };
        } | null>
    >;
    boundRef: any;
    coordinates: { lat: number; lon: number } | null;
    chargingPoints: PublicLocation[];
    setNearbyChargingPoints: Dispatch<
        SetStateAction<{
            closerThan250: PublicLocation[];
            closerThan500: PublicLocation[];
        }>
    >;
}): null {
    const map = useMap();

    map.on("moveend", () => {
        const bounds = map.getBounds();

        setCurrentBounds({
            northEast: {
                lat: bounds.getNorthEast().lat,
                lon: bounds.getNorthEast().lng,
            },
            southWest: {
                lat: bounds.getSouthWest().lat,
                lon: bounds.getSouthWest().lng,
            },
        });
    });

    useEffect(() => {
        if (coordinates) map.fitBounds(boundRef.current.getBounds());
    }, [coordinates?.lat, coordinates?.lon]);

    useEffect(() => {
        if (coordinates) {
            let closerThan250: PublicLocation[] = [];
            let closerThan500: PublicLocation[] = [];
            chargingPoints?.forEach((cp: PublicLocation) => {
                const distance = map.distance(
                    [cp.lat, cp.lon],
                    [coordinates?.lat, coordinates?.lon]
                );
                if (distance <= 250) {
                    closerThan250.push(cp);
                    closerThan500.push(cp);
                } else if (distance <= 500) closerThan500.push(cp);
            });

            setNearbyChargingPoints({ closerThan250, closerThan500 });
        }
    }, [chargingPoints]);

    return null;
}

export function Map({
    answer,
    setAnswer,
}: {
    answer: LocationsCountAnswer;
    setAnswer: (answer: LocationsCountAnswer | null) => void;
}) {
    const {
        coordinates,
        typedAddress,
        setTypedAddress,
        address,
        setAddress,
        chargingPoints,
        currentBounds,
        setCurrentBounds,
        mapCoordinates,
        nearbyChargingPoints,
        setNearbyChargingPoints,
        addressOptions
    } = usePublicChargingData();


    const circleRef = useRef(null);
    const mapRef = useRef(null);
    const { t } = useTranslation("questionnaire");

    const circleMarkOne = L.divIcon({
        html: "250m",
        className: "bg-transparent text-[1.2em] text-Blueberry-dark-default",
    });
    const circleMarkTwo = L.divIcon({
        html: "500m",
        className: "bg-transparent text-[1.2em] text-Blueberry-dark-default",
    });


    useEffect(() => {
        if(!coordinates) {
            setAnswer(null)
            return;
        };
        setAnswer({
            homeAddressInputedAndMappedToCoordinates: Boolean(coordinates),
            countOfLocationsInSmallCircle:
                nearbyChargingPoints.closerThan250.length,
            countOfEvsesInSmallCircle: nearbyChargingPoints.closerThan250
                .map((cp) => cp.countOfEvses)
                .reduce((curr, acc) => curr + acc, 0),
            countOfLocationsInBigCircle:
                nearbyChargingPoints.closerThan500.length,
            countOfEvsesInBigCircle: nearbyChargingPoints.closerThan500
                .map((cp) => cp.countOfEvses)
                .reduce((curr, acc) => curr + acc, 0),
        });
    }, [nearbyChargingPoints]);

    return (
        <div className="w-full ">
            <div className="mb-2">
                {t("chargingPossibilities.myHomeAddress")}
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-4 mt-3">
                <div className={coordinates ? "col-span-2 " : "col-span-3"}>
                    <div className="flex flex-col h-full justify-between">
                        <DropDownInput
                            typedAddress={typedAddress}
                            setTypedAddress={setTypedAddress}
                            address={address}
                            setAddress={setAddress}
                            addressOptions={addressOptions}
                        />
                        {coordinates ? (
                            <div className="border border-Grey-tint rounded h-fit z-40">
                                <MapContainer
                                    id="map"
                                    ref={mapRef}
                                    style={{ height: 320, width: "100%" }}
                                    center={mapCoordinates?.centerPoint}
                                    zoom={15}
                                    zoomSnap={0}
                                    zoomControl={false}
                                    doubleClickZoom={false}
                                    scrollWheelZoom={false}
                                    dragging={false}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                    />
                                    <Circle
                                        className="cursor-auto"
                                        ref={circleRef}
                                        center={[
                                            coordinates.lat,
                                            coordinates.lon,
                                        ]}
                                        pathOptions={{
                                            color: "#B886A6",
                                            fillColor: "#B886A6",
                                            stroke: false,
                                        }}
                                        radius={500}
                                    />

                                    <Circle
                                        className="cursor-auto"
                                        center={[
                                            coordinates.lat,
                                            coordinates.lon,
                                        ]}
                                        pathOptions={{
                                            fillColor: "#961F63",
                                            stroke: false,
                                        }}
                                        radius={250}
                                    />
                                    <Marker
                                        icon={circleMarkOne}
                                        position={[
                                            coordinates.lat - 0.0007,
                                            coordinates.lon + 0.0012,
                                        ]}
                                    />
                                    <Marker
                                        icon={circleMarkTwo}
                                        position={[
                                            coordinates.lat - 0.0025,
                                            coordinates.lon + 0.0035,
                                        ]}
                                    />

                                    <CentralMarker
                                        selectedAddress={coordinates}
                                    />

                                    {chargingPoints.length > 0 && currentBounds
                                        ? chargingPoints?.map((cp: any) => (
                                            <PublicChargersMarkers
                                                key={`${cp.lat}-${cp.lon}`}
                                                cp={cp}
                                                centerPoint={
                                                    mapCoordinates?.centerPoint
                                                }
                                                currentBounds={currentBounds}
                                            />
                                        ))
                                        : null}
                                    <FlyToMap
                                        setCurrentBounds={setCurrentBounds}
                                        boundRef={circleRef}
                                        coordinates={coordinates}
                                        chargingPoints={chargingPoints}
                                        setNearbyChargingPoints={
                                            setNearbyChargingPoints
                                        }
                                    />
                                    {/* Uncomment to access the map's scale */}
                                    {/* <ScaleControl /> */}
                                </MapContainer>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col-span-1 mt-4 md:mt-0">
                    <NearbyChargingPoints
                        coordinates={coordinates}
                        nearbyChargingPoints={nearbyChargingPoints}
                    />
                </div>
            </div>
        </div>
    );
}
