


export function useScroll() {

    const scrollToElementById = (id: string) => {

        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ 
                behavior: "smooth",
                block: "start",
                inline: "nearest"
             });
        }

    }

    const scrollToPosition = (position: number) => {
        window.scroll({
            top: position,
            behavior: "smooth"
        });
    }

    const scrollToTop = () => {
        scrollToPosition(0);
    }

    const scrollToBottom = () => {
        scrollToPosition(document.body.scrollHeight)
    }

    return {
        scrollToPosition,
        scrollToTop,
        scrollToBottom,
        scrollToElementById
    };

}