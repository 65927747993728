import { ReactComponent as LightBulbIcon } from '../../assets/light-bulb.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error-icon.svg';
import { ReactComponent as SuccessIcon } from '../../assets/success-icon.svg';
import { ReactElement, useState } from 'react';


export enum EventState {
    SUCCESS,
    ERROR,
    INFO,
    WARNING
}

export function EventBanner({ state, content }: { state: EventState, content: ReactElement }) {

    const events = {
        [EventState.SUCCESS]: {
            style: 'border-Green-border text-Green-text bg-Green-background',
            icon: <SuccessIcon />,
            crossColor: ""
        },
        [EventState.ERROR]: {
            style: 'border-Red-border text-Red-text bg-Red-background',
            icon: <ErrorIcon />,
            crossColor: "#991B1B"
        },
        [EventState.INFO]: {
            style: 'border-Blue-astronaut bg-white text-Blue-astronaut',
            icon: <LightBulbIcon />,
            crossColor: ""
        },
        [EventState.WARNING]: {
            style: 'border-Blueberry-dark-default bg-Blueberry-light-background text-Blueberry-dark-default',
            icon: <WarningIcon />,
            crossColor: ""
        }
    }


    return (
        <div className={`flex items-center rounded justify-between p-2.5 shadow border ${events[state].style}`}>
            <div className='flex items-center'>
                <div className='mr-4'>{events[state].icon}</div>
                {content}
            </div>
        </div>
    )


}