import { Trans, useTranslation } from "react-i18next";
import { RecommendedAction } from "../../../../types";
import { ArrowRightIcon } from "../../../../components/ArrowRight";
import HomeCharging from '../../../../assets/recommendations/home-charging.webp';
import LongRange from '../../../../assets/recommendations/long-range.webp';
import PublicHomeCharging from '../../../../assets/recommendations/public-home-charging.webp';
import PublicWorkCharging from '../../../../assets/recommendations/public-work-charging.webp';
import VeryLongRange from '../../../../assets/recommendations/very-long-range.webp';
import WorkCharging from '../../../../assets/recommendations/work-charging.webp';
import NoRecommendedActionsIcon from '../../../../assets/recommendations/no-recommendations.webp';
import { useGtmTracking } from "../../../../hooks/useGtmTracking";

const imageMap: Map<RecommendedAction, string> = new Map([
    ["homeChargingNeeded", HomeCharging],
    ["longRangeNeeded", LongRange],
    ["publicChargingNearHomeNeeded", PublicHomeCharging],
    ["publicChargingNearWorkplaceNeeded", PublicWorkCharging],
    ["veryLongRangeNeeded", VeryLongRange],
    ["workplaceChargingNeeded", WorkCharging]
]);

function NoRecommendedActions() {

    const { t, i18n } = useTranslation("results");
    const languageCode = i18n.language;

    const { electrificationPlannerAdTrackingWrapper } = useGtmTracking();

    return <div className="mt-4 flex flex-col w-full h-full md:h-[440px] bg-white rounded p-4 items-center text-black justify-center py-6">
        <div className="flex flex-col w-full h-fit items-center">
            <img src={NoRecommendedActionsIcon} />
            <h1 className=" text-2xl mb-6 mt-2 text-center">{t("recommendedActions.noActionsNecessary")}</h1>
            <div className="flex flex-col w-full items-center px-10 text-xl">
                <span className="text-center">
                    {t("recommendedActions.tryElectrificationPlannerText")}{" "}
                    <span
                        className="text-Blueberry-light-shade underline cursor-pointer"
                        onClick={() => electrificationPlannerAdTrackingWrapper({
                            callback: () => window.open(
                                `${process.env.REACT_APP_WEBSITE_URL}/${languageCode}/drivers/electrification-planner`,
                                "_blank"
                            )
                        })}
                    >{t("recommendedActions.electrificationPlanner")}</span>
                </span>
            </div>
        </div>
    </div>;
}


function Action({ recommendedAction }: { recommendedAction: RecommendedAction }) {

    const { t } = useTranslation("results");

    return <Trans i18nKey="recommendedActions.recommendation" >
        <div key={recommendedAction} className="grid grid-cols-10 md:row-span-1 w-full h-fit p-3 rounded-md border border-Grey-default bg-Grey-background">
            <div className="col-span-7 md:col-span-8 mr-6">
                <div className="flex w-full items-center text-xl mb-1">
                    <ArrowRightIcon color={"#171717"} className="mr-2" />
                    <span >{t(`recommendedActions.recommendations.${recommendedAction}Title`)}</span>
                </div>
                <div className="text-sm pl-6">{t(`recommendedActions.recommendations.${recommendedAction}`)}</div>
            </div>
            <div className="col-span-3 md:col-span-2 w-full h-full flex items-start justify-center">
                <img
                    className="w-28"
                    src={imageMap.get(recommendedAction)}
                />
            </div>
        </div>
    </Trans>;
}


export function RecommendedActions({ recommendedActions }: { recommendedActions: RecommendedAction[] }) {

    const { t } = useTranslation("results");

    return <>
        <div className="border border-white flex justify-center p-2 text-xl rounded">{t("recommendedActions.title")}</div>
        {recommendedActions.length ?
            <div className="mt-4 w-full h-fit md:h-[440px] grid md:grid-rows-3 gap-2 bg-white rounded p-4 items-center text-black ">
                <>{recommendedActions.map((ra: RecommendedAction) => <Action key={ra} recommendedAction={ra} />)}</>
            </div>
            : <NoRecommendedActions />}

    </>;
}