import { useTranslation } from "react-i18next";
import { SuitabilityCategory } from "../../../../types";


function SuitabilityGauge({ suitability }: { suitability: SuitabilityCategory }) {

    const height = 210;
    const width = 300;

    const triangelBase = 150;
    const tirangleWidth = 30;

    const tc = {
        v1: { x: 150, y: 40 },
        v2: { x: 150 - tirangleWidth / 2, y: triangelBase },
        v3: { x: 150 + tirangleWidth / 2, y: triangelBase }
    }

    const needleAngle = {
        "noRestrictions": 90,
        "veryComfortable": 45,
        "fairlyComfortable": 0,
        "adaptationsNeeded": -45,
        "notRecommended": -90
    }


    return (
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
            <g transform="rotate(-110 150 150)">
                <path d="M 150 150 L 150 15 A 135 135 0 0 1 245.4 54.6 Z" fill="#78194F" />
                <path d="M 150 150 L 245.4 54.6 A 135 135 0 0 1 285 150 Z" fill="#B88198" />
                <path d="M 150 150 L 285 150 A 135 135 0 0 1 245.4 245.4 Z" fill="#A2AEB8" />
                <path d="M 150 150 L 245.4 245.4 A 135 135 0 0 1 150 285 Z" fill="#82BA3A" />
                <path d="M 150 150 L 150 285 A 135 135 0 0 1 54.6 245.4 Z" fill="#00AB58" />
                <line x1="150" y1="150" x2="290" y2="10" stroke="white" strokeWidth="5" />
                <line x1="150" y1="150" x2="290" y2="150" stroke="white" strokeWidth="5" />
                <line x1="150" y1="150" x2="150" y2="290" stroke="white" strokeWidth="5" />
                <line x1="150" y1="150" x2="250" y2="250" stroke="white" strokeWidth="5" />
                <circle cx="150" cy="150" r="80" fill="white" />

            </g>
            <g transform={`rotate(${needleAngle[suitability]}, 150, 150)`}>
                <polygon points={`${tc.v1.x},${tc.v1.y} ${tc.v2.x},${tc.v2.y} ${tc.v3.x},${tc.v3.y}`} fill="#2C2C31" />
                <circle cx="150" cy="150" r="15" fill="#2C2C31" />
            </g>
        </svg>
    )

}

export function ElectrificationAssessment({ suitabilityCategory }: { suitabilityCategory: SuitabilityCategory }) {

    const { t } = useTranslation("results");

    return (
        <div>
            <div className="border border-white flex justify-center p-2 text-xl rounded">{t("electrificationAssessment.title")}</div>
            <div className="mt-4 flex flex-col w-full md:h-[440px] bg-white rounded p-6 items-center text-black px-8">
                <div className="flex flex-col items-center ">
                    <SuitabilityGauge suitability={suitabilityCategory} />
                    <h1 className="mt-3 text-3xl mb-6 text-center">{t(`electrificationAssessment.suitability.${suitabilityCategory}`)}</h1>
                </div>
                <h3 className="text-center">{t(`electrificationAssessment.suitability.${suitabilityCategory}Msg`)}</h3>
            </div>
        </div>
    )

}