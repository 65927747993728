import { ReactComponent as RealRangeIconSmall } from "../../../../assets/ev_details/real-range-white-small.svg";
import { ReactComponent as EnergyCostIconSmall } from "../../../../assets/ev_details/energy-cost-small.svg";
import { ReactComponent as ListPriceIconSmall } from "../../../../assets/ev_details/list-price-small.svg";

import { useEvDetails } from "./useEvDetails";
import { EvDetails } from "../../../../electrify_frontend_common/components/EvDetails";
import { ListPrice } from "../../../../electrify_frontend_common/icons/ListPrice";
import { RealRange } from "../../../../electrify_frontend_common/icons/RealRange";
import { MetricsDisplay } from "../../../../electrify_frontend_common/components/EvDetails/components";
import { EnergyCost } from "../../../../electrify_frontend_common/icons/EnergyCost";
import { VehicleDetailsTable } from "../../../../electrify_frontend_common/components/EvDetails/VehicleDetailsTable";
import { ArrowLeft } from "../../../../electrify_frontend_common/components/ArrowLeft";



export function EvDetailedView() {

    const {
        t,
        isMobileView,
        mainStats,
        currencySymbol,
        vehicleData,
        headerTitle
    } = useEvDetails();



    if (!vehicleData) return null;

    return (
        <div className="flex flex-col w-full h-full pt-12 p-0 sm:p-20 md:p-20 lg:p-40 lg:pt-20 items-center ">
            <div className="p-6 pt-4 max-w-screen-lg">
                <div className="flex items-center mb-10">
                    <div className="block md:hidden mr-4 cursor-pointer" onClick={() => window.history.back()}>
                        <ArrowLeft />
                    </div>
                    <h1 className=" text-3xl font-bold text-white">
                        {headerTitle}
                    </h1>
                </div>
                <EvDetails
                    vehicle={vehicleData}
                    vehicleStats={
                        <>
                            <div className="grid grid-cols-3 gap-4 mt-12">
                                <MetricsDisplay
                                    Icon={isMobileView ? <ListPriceIconSmall /> : <ListPrice color={"white"} />}
                                    property={t("vehicleDetailedView.mainStats.listPrice")}
                                    value={mainStats?.listPrice}
                                    units={currencySymbol}
                                    backgroundColor="dark"
                                />
                                <MetricsDisplay
                                    Icon={isMobileView ? <RealRangeIconSmall /> : <RealRange color="white" />}
                                    property={`${t("vehicleDetailedView.mainStats.realRange")} *`}
                                    value={mainStats?.realRange}
                                    units={"km"}
                                    backgroundColor="dark"
                                />
                                <MetricsDisplay
                                    Icon={isMobileView ? <EnergyCostIconSmall /> : <EnergyCost color="white" />}
                                    property={t("vehicleDetailedView.mainStats.energyConsumption")}
                                    value={mainStats?.energyConsumption}
                                    units={`kWh / 100 km`}
                                    backgroundColor="dark"
                                />
                            </div>

                            <VehicleDetailsTable backgroundColor="dark" vehicleData={vehicleData} />
                        </>
                    }
                />
            </div>
        </div>

    );
}
