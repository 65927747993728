import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const LANGUAGES = {
    en: "en",
    de: "de",
};

/* Check existance of language on URL */
const languageOnUrl = new URLSearchParams(window.location.search).get(
    "lang"
) as string;

// const languageOnUrl =
//   urlText && Object.values(LANGUAGES).includes(urlText) && urlText;

/* Select language with hierarchy: URL > languageOnLocalStorage > default EN */
const defaultLanguage = languageOnUrl ? languageOnUrl : LANGUAGES.en;

document.documentElement.lang = defaultLanguage;

i18n.use(initReactI18next).init({
    lng: defaultLanguage,
    fallbackLng: LANGUAGES.en,
    resources: {
        en: {
            // Pages
            welcome: require("./static/en/welcome.json"),
            questionnaire: require("./static/en/questionnaire.json"),
            results: require("./static/en/results.json"),
            evFinder: require("./electrify_frontend_common/static/en/components/evFinder.json"),
            evDetails: require("./static/en/evDetails.json"),
        },
        de: {
            // Pages
            welcome: require("./static/de/welcome.json"),
            questionnaire: require("./static/de/questionnaire.json"),
            results: require("./static/de/results.json"),
            evFinder: require("./electrify_frontend_common/static/de/components/evFinder.json"),
            evDetails: require("./static/de/evDetails.json"),
        },
    },
    ns: ["common"],
    defaultNS: "common",
    fallbackNS: "common",
    debug: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
