import { LocationsCountAnswer } from "../../../../../types";
import { Map } from "./Map";

export function ChargingPossibilities({
    answer,
    setAnswer,
}: {
    answer: LocationsCountAnswer;
    setAnswer: (answer: LocationsCountAnswer | null) => void;
}) {

    return (
        <div className="flex flex-col w-full items-center justify-center text-white">
            <Map answer={answer} setAnswer={setAnswer} />
        </div>
    );
}
