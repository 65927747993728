import { useTranslation } from "react-i18next";
import { AnswerButton } from "../components";
import { PriceQuestionOptions } from "../../../../types";



export function Price({ setAnswer, answer }: { setAnswer: (v: string) => void, answer: string }) {

    const {t} = useTranslation("questionnaire");

    return (
        <div className="w-full sm:grid sm:grid-cols-5 sm:gap-4">
            {Object.entries(PriceQuestionOptions).map(([k, v]) => <AnswerButton key={v} text={t(`price.options.${v}`)} isActive={answer === v} onClick={() => setAnswer(v)} />)}
        </div>
    )

}