import { ArrowLeft } from "../../../../components/ArrowLeft";
import { useState } from "react";

export function BackButton({ goBack }: { goBack: () => void }) {

    const [hovered, setHovered] = useState(false);

    return (
        <div className="flex w-full justify-start md:mb-4">
            <div
                className="flex items-center cursor-pointer pb-8 md:pb-0 w-fit text-white hover:text-Blueberry-dark-default"
                onClick={goBack}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <ArrowLeft color={hovered ? "#830751" : "white"} />
                <span className="text-sm  ml-2 underline ">Return to questionnaire</span>
            </div>
        </div>
    )
}