import { Question } from "../types";


export type GTMEvents = "buttonClick" | "valueUpdated" | "productLoaded" | "callToAction";

export type DataLayerEventData = {
    event: GTMEvents;
    metadata: {
        product: "quickCheck" | "evFinder";
        page: Question | "welcome" | "results" | "-";
        value?: "next" | "back" | "skip" | "startOver" | "filters" | "sorting" | "moreInfo" | "electrificationPlanner" | "electrifyDriver" | "evFinder" | "leasingOffer";
    }
}

export function pushToDataLayer(data: DataLayerEventData) {
    window.dataLayer.push(data);
}

export function useGtmTracking() {

    const questionnaireEventTrackingWrapper = ({ action, page, callback }: { action: 'next' | 'back' | 'skip' | "startOver", page: Question | "welcome" | "results", callback: () => void }) => {
        pushToDataLayer({
            event: 'buttonClick',
            metadata: {
                product: 'quickCheck',
                page,
                value: action
            }
        });
        callback();
    };

    const evFinderAdTrackingWrapper = ({ callback }: { callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product: 'quickCheck',
                page: 'results',
                value: 'evFinder'
            }
        })

        callback();
    }

    const electrificationPlannerAdTrackingWrapper = ({ callback }: { callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product: 'quickCheck',
                page: 'results',
                value: 'electrificationPlanner'
            }
        })

        callback();
    }

    const electrifyDriverAdTrackingWrapper = ({ product, callback }: { product: 'evFinder' | 'quickCheck', callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product,
                page: product === 'quickCheck' ? 'results' : "-",
                value: 'electrifyDriver'
            }
        })

        callback();
    }


    const evFinderEventTracking = (event: 'filters' | 'sorting' | 'moreInfo', product: 'evFinder' | 'quickCheck', page: 'results' | '-') => {
        pushToDataLayer({
            event: event !== 'moreInfo' ? 'valueUpdated' : 'buttonClick',
            metadata: {
                product,
                value: event,
                page
            }
        })
    }


    const offerClickedEventTracking = ({ product, callback }: { product: 'evFinder' | 'quickCheck', callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product,
                page: product === 'quickCheck' ? 'results' : '-',
                value: 'leasingOffer'
            }
        })
        callback();
    };


    return {
        questionnaireEventTrackingWrapper,
        evFinderAdTrackingWrapper,
        electrificationPlannerAdTrackingWrapper,
        electrifyDriverAdTrackingWrapper,
        evFinderEventTracking,
        offerClickedEventTracking
    }


}