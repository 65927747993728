import { useTranslation } from "react-i18next";
import { AnswerButton } from "../components";
import { WorkplaceChargingFrequencyQuestionOptions } from "../../../../types";


export function WorkplaceAttendancy({ setAnswer, answer }: { setAnswer: (v: string) => void, answer: string }) {

    const { t } = useTranslation("questionnaire");

    return (
        <div className="w-full sm:grid sm:grid-cols-6 sm:gap-4">
            {Object.entries(WorkplaceChargingFrequencyQuestionOptions).map(([k, v]) => <AnswerButton key={v} text={t(`workplaceAttendancy.options.${v}`)} isActive={answer === v} onClick={() => setAnswer(v)} />)}
        </div>
    )

}