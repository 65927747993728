import { useEffect, useMemo, useState } from "react";
import { LatLngExpression } from "leaflet";
import { GoogleAutocompletePredictionsResponse, PublicLocation } from "../../../../../types";
import { useQuery } from "react-query";
import {
    getAddressCoordinates,
    getAddressOptions,
    getChargingPoints,
} from "../../../../../services";

export function usePublicChargingData() {

    const [currentBounds, setCurrentBounds] = useState<{
        southWest: { lat: number; lon: number };
        northEast: { lat: number; lon: number };
    } | null>(null);

    const [chargingPoints, setChargingPoints] = useState<PublicLocation[]>([]);
    const [coordinates, setCoordinates] = useState<{
        lat: number;
        lon: number;
    } | null>(null);

    const [nearbyChargingPoints, setNearbyChargingPoints] = useState<{
        closerThan250: PublicLocation[];
        closerThan500: PublicLocation[];
    }>({ closerThan250: [], closerThan500: [] });



    const storedLocationData = localStorage.getItem("userLatestHomeLocation");
    const userLatestHomeLocation: {
        address: {value: string, isHouseAddress: boolean};
        coordinates: { lat: number; lon: number };
        countryCode: string;
    } = storedLocationData && JSON.parse(storedLocationData);

    const lastAddress = userLatestHomeLocation?.address;

    const [address, setAddress] = useState<{value: string, isHouseAddress: boolean}>({value: lastAddress?.value || "", isHouseAddress: !!lastAddress?.isHouseAddress});

    const [typedAddress, setTypedAddress] = useState(lastAddress?.value || "");


    useEffect(() => {
        if (userLatestHomeLocation?.coordinates)
            setCoordinates(userLatestHomeLocation.coordinates);
    }, [lastAddress?.value]);

    const isAddressProvided = Boolean(address?.value && address?.value.length > 3);

    function setNoAddress() {
        if (coordinates) {
            setCoordinates(null);
            localStorage.removeItem("userLatestHomeLocation");
            setNearbyChargingPoints({ closerThan250: [], closerThan500: [] });
        }
    }

    const [addressOptions, setAddressOptions] = useState<GoogleAutocompletePredictionsResponse[]>([]);

    useQuery([typedAddress], async () => {
        const options = await getAddressOptions(typedAddress);
        setAddressOptions(options);
    });

    useQuery([address?.value], () => getAddressCoordinates(address?.value), {
        enabled: isAddressProvided && address !== lastAddress,
        retry: false,
        onSuccess: (data) => {
            if (data) {
                setTypedAddress(address?.value);

                localStorage.setItem(
                    "userLatestHomeLocation",
                    JSON.stringify({
                        address: address,
                        coordinates: {
                            lat: parseFloat(data.latitude),
                            lon: parseFloat(data.longitude),
                        },
                        countryCode: data.countryCode,
                    })
                );
            }

        },
        onError: (error) => setNoAddress(),
    });

    useEffect(() => {
        if (!isAddressProvided) setNoAddress();
    }, [address]);

    const mapCoordinates = useMemo((): {
        centerPoint: LatLngExpression;
        coordinates: LatLngExpression;
    } | null => {
        if (!coordinates) return null;
        return {
            centerPoint: [coordinates.lat + 0.0002, coordinates.lon],
            coordinates: [coordinates.lat, coordinates.lon],
        };
    }, [coordinates?.lat, coordinates?.lon]);

    useQuery<PublicLocation[]>(
        [
            currentBounds?.northEast.lat,
            currentBounds?.northEast.lon,
            currentBounds?.southWest.lat,
            currentBounds?.southWest.lon,
        ],
        () => getChargingPoints(currentBounds),
        {
            enabled: Boolean(currentBounds),
            cacheTime: 0,
            onSuccess: (data: PublicLocation[]) => {
                if (data) {
                    setChargingPoints([...data]);
                }
            },
        }
    );

    return {
        homeAddressesLoading: null,
        workplaceLocationsLoading: null,
        chargingPoints,
        setCurrentBounds,
        currentBounds,
        coordinates,
        address,
        setAddress,
        typedAddress,
        setTypedAddress,
        mapCoordinates,
        nearbyChargingPoints,
        setNearbyChargingPoints,
        addressOptions
    };
}
