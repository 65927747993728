import { useContext, useMemo, useState } from "react";
import { useScreenSize } from "../../hooks/useScreenSize";
import { useQuery } from "react-query";
import { getEvs } from "../../services";
import { useScroll } from "../../hooks/useScroll";
import { EvFinderContext } from "../EvFinderContext";
import { AlternativeVehicle } from "../../electrify_frontend_common/types";



export function useVehicleList() {

    const {filters, sortBy, updateSortBy, page, setPage} = useContext(EvFinderContext);

    const { isMobileView, isTabletView, widthIsLessThan1024 } = useScreenSize();
    
    const PAGE_SIZE = useMemo(() => {
        return isMobileView ? 6 : 12;
    }, [isTabletView])

    const { scrollToElementById } = useScroll();
    const [vehicles, setVehicles] = useState<Partial<AlternativeVehicle>[][]>([]);
    const [lastPagesCount, setLastPagesCount] = useState(20);
    const [firstLoad, setFirstLoad] = useState(true);



    const userCountryCode: string = "DE"; // Should be taken from either the IP of user or the country set for the device of user in the future

    const handleVehiclesFetchSuccess = (data: {
        page: number;
        pageSize: number;
        totalPagesCount: number;
        electricVehicles: any[];
        totalVehiclesCount: number;
    }) => {
        setFirstLoad(false);
        const vehicles = data?.electricVehicles;
        if (!firstLoad && vehicles?.length > 0 && !widthIsLessThan1024) {
            scrollToElementById("vehicle-list")
        };

        setLastPagesCount(data?.totalPagesCount || 20);
        setPage(data?.page || 1);
        const halfPagePt1 = !isTabletView ? PAGE_SIZE / 2 : PAGE_SIZE / 3;
        const halfPagePt2 = !isTabletView ? PAGE_SIZE / 2 : PAGE_SIZE / 3;
        if (vehicles.length > (PAGE_SIZE / 2)) {
            setVehicles(new Array(vehicles.slice(0, halfPagePt1), vehicles.slice(halfPagePt2, PAGE_SIZE)));
            return;
        }
        setVehicles([vehicles, []]);
        return;
    }

    const { data, isLoading } = useQuery(
        ["vehicles", page, filters, sortBy],
        () =>
            getEvs({
                sortBy,
                selectedFilters: filters,
                page,
                pageSize: PAGE_SIZE,
                userCountryCode: userCountryCode || "DE",
            }),
        {
            onSuccess: (data) => handleVehiclesFetchSuccess(data),
            refetchOnWindowFocus: false,
        }
    );





    return {
        data,
        widthIsLessThan1024,
        vehicles,
        isLoading,
        sortBy,
        updateSortBy,
        lastPagesCount,
        page,
        setPage
    };



}