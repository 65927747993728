import { useTranslation } from "react-i18next";
import { GoogleAutocompletePredictionsResponse } from "../../../../../types";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ReactComponent as AutocompleteIconWhite } from "../../../../../assets/autocomplete_location_white.svg";
import { ReactComponent as HomeIcon } from "../../../../../assets/home.svg";

import { EventBanner, EventState } from "../../../../../components/EventBanner";
import { useOutsideAlerter } from "../../../../../electrify_frontend_common/hooks/useOutsideClickAlerter";


function useKeydownHandler({
    addressOptions,
    setSelectedDropdownLine,
    selectedDropdownLine,
    pickAddress
}: {
    addressOptions?: GoogleAutocompletePredictionsResponse[],
    selectedDropdownLine: number,
    setSelectedDropdownLine: Dispatch<SetStateAction<number>>,
    pickAddress: ({value, isHouseAddress} : {value: string, isHouseAddress: boolean}) => void
}) {

    const handleKeyDown = (e: KeyboardEvent) => {

        if (!addressOptions?.length) return;

        if (e.key === "ArrowDown") {
            e.preventDefault();
            setSelectedDropdownLine((prev) => {
                if (prev < addressOptions?.length - 1 || prev == -1) return prev + 1;
                return -1;
            });
        } else if (e.key === "ArrowUp") {
            e.preventDefault();

            setSelectedDropdownLine((prev) => {
                if (prev >= 1) return prev - 1;
                else if (prev == -1) return addressOptions.length - 1;
                return -1;

            });
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (selectedDropdownLine === -1) return;
            
            const address = addressOptions[selectedDropdownLine];
            pickAddress({value: address.description, isHouseAddress: address.isHouseAddress});
        }
    };

    useEffect(() => {
        window.removeEventListener("keydown", handleKeyDown);
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [addressOptions, selectedDropdownLine])




}


function Dropdown({ addressOptions, pickAddress }: { addressOptions?: GoogleAutocompletePredictionsResponse[], pickAddress: ({value, isHouseAddress} : {value: string, isHouseAddress: boolean}) => void}) {

    const [selectedDropdownLine, setSelectedDropdownLine] = useState<number>(0);


    useKeydownHandler({ addressOptions, selectedDropdownLine, pickAddress, setSelectedDropdownLine });

    return (
        <div className="flex flex-col w-full absolute top-14"
            onMouseLeave={() => setSelectedDropdownLine(-1)}
        >
            <div className=" border border-white bg-[#1e1e21] rounded-b z-50 ">
                {addressOptions?.map((option, index) => (
                    <DropdownItem
                        key={index}
                        index={index}
                        selected={selectedDropdownLine === index}
                        setSelectedDropdownLine={setSelectedDropdownLine}
                        pickAddress={pickAddress}
                        option={option}
                    />
                ))}
            </div>
        </div>
    )

}


function DropdownItem({
    pickAddress,
    selected,
    setSelectedDropdownLine,
    option,
    index
}: {
    index: number,
    selected: boolean,
    pickAddress: ({value, isHouseAddress} : {value: string, isHouseAddress: boolean}) => void,
    option: GoogleAutocompletePredictionsResponse,
    setSelectedDropdownLine: Dispatch<SetStateAction<number>>
}) {


    const boldSubstrings = () => {
        let outputString = '';
        let currentIndex = 0;
        option.matched_substrings.forEach(({ offset, length }) => {
            outputString += option.description.substring(currentIndex, offset);
            outputString += '<b style="font-size: 16px">' + option.description.substring(offset, offset + length) + '</b>';
            currentIndex = offset + length;
        });
        outputString += option.description.substring(currentIndex);
        return outputString;
    }

    return (
        <div

            onClick={() => {
                pickAddress({value: option.description, isHouseAddress: option.isHouseAddress})
            }}
            onMouseEnter={() => setSelectedDropdownLine(index)}
            className={`flex items-center w-full cursor-pointer ${selected ? 'bg-Blueberry-dark-default' : ''}`}
        >
            <div className="m-4 flex items-center w-full cursor-pointer overflow-hidden">
                    <div className="h-6">
                       {option.isHouseAddress ? <HomeIcon className="mr-2"/> : <AutocompleteIconWhite className="mr-2 " />}
                    </div>
                    <span  className="text-white font-thin whitespace-nowrap truncate" dangerouslySetInnerHTML={{ __html: boldSubstrings() }}></span>
                 
            </div>

        </div>
    )


}


export function DropDownInput({ typedAddress, setTypedAddress, address, setAddress, addressOptions }: { typedAddress: string, setTypedAddress: (address: string) => void, address : {value: string, isHouseAddress: boolean}, setAddress: (address: {value: string, isHouseAddress: boolean}) => void, addressOptions?: GoogleAutocompletePredictionsResponse[] }) {

    const { t } = useTranslation("questionnaire");

    const MIN_CHARACTERS_FOR_NOT_FOUND_ERROR = 2;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notFoundErrorCanBeDisplayed, setNotFoundErrorCanBeDisplayed] = useState(false);
    const [noAddressErrorCanBeDisplayed, setNoAddressErrorCanBeDisplayed] = useState(false);

    const dropdownRef = useRef(null);

    const handleOutsideClick = () => {
        setNoAddressErrorCanBeDisplayed(true);
        setDropdownOpen(false);
    }

    useOutsideAlerter(dropdownRef, handleOutsideClick);


    const showDropdown = addressOptions?.length && dropdownOpen;
    const notFoundError = addressOptions?.length === 0 && notFoundErrorCanBeDisplayed;
    const noAddressError = typedAddress.length > 0 && !!addressOptions?.length && noAddressErrorCanBeDisplayed;
    const addressRefinementPossible = !!address.value.length && !address.isHouseAddress;

    return (
        <>
            {showDropdown ? <div className="absolute left-0 bottom-0 flex w-full h-screen  "></div> : null}
            <div ref={dropdownRef} className="flex flex-col w-full mb-8 relative ">
                <input
                    type="text"
                    className="w-full bg-transparent border border-white outline-none focus:border-Blueberry-light-shade rounded-t p-4 truncate"
                    style={{
                        boxShadow: 'none'
                    }}
                    placeholder={t(
                        "chargingPossibilities.myHomeAddressPlaceholder"
                    )}
                    value={typedAddress}
                    onChange={(e) => {
                        setTypedAddress(e.target.value)
                        setDropdownOpen(true);
                        if (e.target.value.length > MIN_CHARACTERS_FOR_NOT_FOUND_ERROR) {
                            setNotFoundErrorCanBeDisplayed(true);
                        } else {
                            setNotFoundErrorCanBeDisplayed(false);
                        }
                        // TO RESET THE ADDRESS
                        setAddress({value: "", isHouseAddress: false});
                        setNoAddressErrorCanBeDisplayed(false);
                    }}
                />
                {showDropdown ?
                    <Dropdown
                        addressOptions={addressOptions}
                        pickAddress={({value, isHouseAddress} : {value: string, isHouseAddress: boolean}) => {
                            setAddress({value, isHouseAddress});
                            setDropdownOpen(false);
                        }}
                    />
                    : null}

                {
                    notFoundError ?
                        <div className="mt-6 absolute top-12 md:top-16 w-full">
                            <EventBanner state={EventState.ERROR} content={<div>{t("chargingPossibilities.myHomeAddressNotFoundError")}</div>} />
                        </div>
                        : null
                }
                {
                    noAddressError ?
                        <div className="mt-6 absolute top-12 md:top-16 w-full">
                            <EventBanner state={EventState.ERROR} content={<div>{t("chargingPossibilities.myHomeAddressNotPickedError")}</div>} />
                        </div>
                        : null
                }
                {
                    addressRefinementPossible ?
                        <div className="mt-0 absolute top-[-46px] w-full">
                            <EventBanner state={EventState.WARNING} content={<div>{t("chargingPossibilities.myHomeAddressCanBeRefinedWarning")}</div>} />
                        </div>
                        : null
                }

            </div>

        </>

    )


}