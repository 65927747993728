import { useTranslation } from "react-i18next";
import { AnswerButton } from "../components";
import { ChargingQuestionOptions } from "../../../../types";





export function Charging({ setAnswer, answer }: { setAnswer: (v: string) => void, answer: string }) {

    const { t } = useTranslation("questionnaire");

    return (
        <div className="w-full sm:grid sm:grid-cols-4 sm:gap-4">
            {Object.entries(ChargingQuestionOptions).map(([k, v]) => <AnswerButton
                key={v}
                text={t(`homeCharging.options.${v}`)}
                isActive={answer === v}
                onClick={() => setAnswer(v)}
            />)}
        </div>
    )

}