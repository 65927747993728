import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { products } from "./products";

const queryClient = new QueryClient();


function App() {

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <div
                    className={`flex flex-col grow w-full min-h-screen ${process.env.REACT_APP_ENV !== "development"
                        ? "bg-transparent"
                        : "bg-Black-background"
                        }`}
                >
                    <Routes>
                        {products.map((dr) => (
                            <Route
                                key={dr.path}
                                path={dr.path}
                                element={<dr.component />}
                            />
                        ))}
                    </Routes>
                </div>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
