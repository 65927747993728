import { ElectrifyDriveBanner } from "../../E-MobilityQuickCheck/pages/Results/components/ElectrifyDriveBanner";
import { Spinner, SpinnerSize } from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import { useVehicleList } from "./useVehicleList";
import { alternativesSortMap } from "../EvFinderContext";
import { FreeEvFinderVehicleCard } from "../../electrify_frontend_common/components/EvFinder/Versions[A-B]/FreeEvFinderVehicleCard";
import { AlternativeVehicle } from "../../electrify_frontend_common/types";
import { useMoreInfo } from "../../hooks/useMoreInfo";
import { SelectionSorter } from "../../electrify_frontend_common/components/EvFinder/Versions[A-B]/components";
import { PaginationSelector } from "../../electrify_frontend_common/components/EvFinder/PaginationSelector";
import { useGtmTracking } from "../../hooks/useGtmTracking";



function EvCount({ text, count }: { text: string, count: number }) {
    return (
        <div>
            <span className="text-sm">{text}: </span>
            <span className="text-sm md:text-lg font-bold">
                {count}
            </span>
            <span className="text-base"> EVs</span>
        </div>
    )
}

export function VehicleList() {
    const { t, i18n } = useTranslation("evFinder");

    const { data, widthIsLessThan1024, vehicles, isLoading, sortBy, updateSortBy, setPage, page } = useVehicleList();

    const { openVehicleDetails } = useMoreInfo('evFinder');

    const { offerClickedEventTracking } = useGtmTracking();



    return <div id="vehicle-list" className="flex flex-col w-full h-full pb-6 sm:pb-0">
        <div className="md:flex md:justify-between mb-9 md:mb-2 md:items-center">
            <div className={`${widthIsLessThan1024 ? 'hidden' : 'block'}`}>
                <EvCount text={t("resultsCountText")} count={data?.totalVehiclesCount || 0} />
            </div>

            <SelectionSorter backgroundColor="dark" sortBy={sortBy} handleOnChangeSorter={updateSortBy} optionsMap={alternativesSortMap} />

            <div className={`${widthIsLessThan1024 ? 'block mt-2' : 'hidden'}`}>
                <EvCount text={t("resultsCountText")} count={data?.totalVehiclesCount || 0} />
            </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mt-3.5">
            {isLoading ? (
                <div className="flex flex-col col-span-3 h-96 items-center justify-center">
                    <Spinner size={SpinnerSize.LARGE} />
                </div>
            ) : null}
            {!isLoading
                ? vehicles[0]?.map((evs: Partial<AlternativeVehicle>) => (
                    <div key={evs.evId} className="col-span-1">
                        <FreeEvFinderVehicleCard
                            vehicle={evs}
                            onClick={() => openVehicleDetails(evs.evId || "")}
                            localeCode={i18n.language}
                            offerTrackingCall={(callback: () => void) => {
                                offerClickedEventTracking({
                                    product: 'evFinder',
                                    callback
                                })
                            }}
                        />
                    </div>
                ))
                : null}
            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
                <ElectrifyDriveBanner className="p-14" product='evFinder' />
            </div>
            {!isLoading
                ? vehicles[1]?.map((evs: Partial<AlternativeVehicle>) => (
                    <div key={evs.evId} className="col-span-1">
                        <FreeEvFinderVehicleCard
                            vehicle={evs}
                            onClick={() => openVehicleDetails(evs.evId || "")}
                            localeCode={i18n.language}
                            offerTrackingCall={(callback: () => void) => {
                                offerClickedEventTracking({
                                    product: 'evFinder',
                                    callback
                                })
                            }}
                        />
                    </div>
                ))
                : null}
        </div>
        <PaginationSelector currentPage={page} setPage={setPage} totalPagesCount={data?.totalPagesCount} />
    </div>
}
