import { useTranslation } from "react-i18next";
import electrifyDriverOverviewImage from "../../../../assets/marketing_banners/electrify-driver-overview.webp";
import { ArrowRightIcon } from "../../../../components/ArrowRight";
import { Button } from "../../../../components/Button";
import { ButtonSize, ButtonVariant } from "../../../../components/Button/types";
import { useGtmTracking } from "../../../../hooks/useGtmTracking";

export function ElectrifyDriveBanner({ className, product }: { className?: string, product: 'evFinder' | 'quickCheck' }) {
    const { t, i18n } = useTranslation("results");
    const languageCode = i18n.language;

    const { electrifyDriverAdTrackingWrapper } = useGtmTracking();


    return <div className={`sm:flex w-full p-6 bg-gradient-to-r from-Blueberry-light-default to-Blueberry-light-shade rounded text-white ${className}`}>
        <div className="flex w-full mb-4 sm:mb-0 sm:w-fit justify-center items-center">
            <img className="min-w-40 h-36" src={electrifyDriverOverviewImage} alt={t("driverBanner.imageAltText")} loading="lazy" />
        </div>
        <div className="flex flex-col sm:ml-10 justify-between">
            <div className="flex flex-col w-full">
                <div className="text-lg">{t("driverBanner.msg1")}</div>
                <div className="text-sm mt-2">{t("driverBanner.msg2")}</div>
            </div>
            <div className="pb-2 mt-4 sm:mt-2">
                <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                        electrifyDriverAdTrackingWrapper({
                            product,
                            callback: () => window.open(
                                `${process.env.REACT_APP_WEBSITE_URL}/${languageCode}/drivers/electrify-driver`,
                                "_blank"
                            )

                        })
                    }}
                >
                    <div className="flex justify-between items-center">
                        {t("driverBanner.buttonText")}
                        <ArrowRightIcon className="ml-4" />
                    </div>
                </Button>
            </div>
        </div>
    </div>;
}
